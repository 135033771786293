@import "@/assets/scss/var.scss";
.coupon {
    .el-tabs /deep/ {
        font-size: 14px;
        .el-tab-pane {
            margin-left: -10px;
            margin-right: -10px;
            display: flex;
            flex-wrap: wrap;
            color: #343635;
        }
        .Coupon-item {
            justify-content: space-between;
            padding: 10px 15px;
            border: 1px solid #ebedf0;
            overflow: visible;
            border-left: 4px solid $color-primary;
            min-height: 70px;
            margin: 10px;
            margin-bottom: 0;
            width: calc(45% - 25px);
            .l {
                width: 230px;
            }
            .r > div .num {
                font-size: 19px;
            }
        }
    }
}
